import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../components/loader";
import Cookie from "js-cookie";
import { Link } from "react-router-dom";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const formData = {
    email,
    password,
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/user/signin`,
        formData
      );

      if (response.data.error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "question",
          title: response.data.error,
        });
        setLoading(false);
      }

      if (response.data.message) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: response.data.message,
        });
        setLoading(false);
        Cookie.set("user-token", response.data.token);
        Cookie.set("user-detail", JSON.stringify(response.data.user));
        Cookie.set("apiKey", response.data?.apiKey);
        if (response.data.role === "admin") {
          window.location.replace("/admin/dashboard");
        } else if (response.data.role === "user") {
          window.location.replace("/user/dashboard");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full overflow-hidden">
      <div
        className="flex h-screen items-center justify-center bg-gray-900 bg-cover bg-no-repeat w-full overflow-hidden"
        style={{
          backgroundImage: "url('/banner.jpeg')",
        }}
      >
        <div className="flex p-4">
          <div className="w-[50%] lg:block hidden">
            <img
              className="w-full object-cover rounded-l h-full"
              src="/login.jpeg"
              alt="login image"
            />
          </div>
          <div className="rounded-r bg-gray-800 bg-opacity-50 lg:px-4 px-2 lg:py-10 py-8 shadow-lg backdrop-blur-md lg:w-[50%] w-[100%] ">
            <div className="text-white">
              <div className="mb-8 flex flex-col items-center justify-center">
                <span className="text-gray-50 text-2xl py-3">Mau Entrance</span>
                <span className="text-gray-300">Enter Login Details</span>
              </div>
              <form className="text-center w-full" onSubmit={handleSubmit}>
                <div className="mb-4 text-lg">
                  <input
                    className="rounded border-none bg-gray-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-100 shadow-lg outline-none backdrop-blur-md w-full"
                    type="email"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4 text-lg">
                  <input
                    className="rounded border-none bg-gray-400 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-100 shadow-lg outline-none backdrop-blur-md w-full"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <div className="mb-3 flex justify-between items-center">
                    <p
                      onClick={handleTogglePassword}
                      className="bg-gray-500 rounded text-gray-100 px-2 py-1 lg:w-[28%] w-[50%] text-xs cursor-pointer"
                    >
                      {showPassword ? "Hide Password" : "Show Password"}
                    </p>
                    <Link to="/forgot-password">
                      <p className="rounded text-gray-100 px-2 py-1 lg:w-[100%] w-[50%] text-xs cursor-pointer">
                        Forgot password ?
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="mt-8 flex justify-center text-lg text-black">
                  <button
                    onClick={handleSubmit}
                    className="rounded-3xl bg-green-400 bg-opacity-50 px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-green-600"
                  >
                    Login
                  </button>
                </div>
                {/* <p className="text-center text-sm font-sans font-semibold tracking-wider mt-4">
                  New to website ?{" "}
                  <Link to="/signup">click here to register.</Link>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Login;
