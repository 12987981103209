import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-100 via-green-200 to-gray-100">
      <div className="container mx-auto px-4 lg:py-2 py-2 sm:px-6 lg:px-8">
        <p className="text-xs text-gray-800 text-center">© 2024 Mau Entrance</p>
      </div>
    </footer>
  );
};

export default Footer;
