import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Dreadcrumb from "../dreadcrumb";

const UserData = ({ closeUserDetails, userId }) => {
  const [userData, setUserData] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [modalImgUrl, setModalImgUrl] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/get/user-details/${userId}`
      );
      setUserData(response.data);
    };
    fetchData();
  }, [userId]);

  return (
    <div className="lg:mb-16 mb-[2rem] absolute top-0 inset-0 bg-gray-100 z-[999] h-screen overflow-y-auto">
      <div className="flex justify-end ">
        <button
          onClick={closeUserDetails}
          className="m-4 bg-red-600 text-white px-2 py-1 text-sm rounded"
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <Dreadcrumb page="user details" />

      {imageModal && (
        <div className="absolute lg:top-0 top-16 inset-0 px-4">
          <div className="flex justify-end">
            <button onClick={() => setImageModal(false)}>
              <i className="fa fa-times text-xl"></i>
            </button>
          </div>
          <img src={modalImgUrl} className="w-full" />
        </div>
      )}

      {userData?.payment ? (
        <div className="shadow m-4 rounded p-3 capitalize flex flex-col gap-2">
          <p className="text-lg text-gray-600 font-semibold">
            Payment Details:
          </p>
          <p className="font-semibold">
            Transaction Id:{" "}
            <span className="text-gray-600 ml-2">
              {userData.payment?.transId}
            </span>
          </p>
       
          <p className="font-semibold">
            Payment by:{" "}
            <span className="text-gray-600 ml-2">
              {userData.payment?.paymentVia}
            </span>
          </p>
          <p className="font-semibold">
            Payment initiated:{" "}
            <span className="text-gray-600 ml-2">
              {userData.payment?.createdAt &&
                new Date(userData.payment.createdAt).toLocaleString()}
            </span>
          </p>
          <p className="font-semibold">
            Amount:{" "}
            <span className="text-gray-600 ml-2">
              Rs. {userData.payment?.amount}
            </span>
          </p>
          <p className="font-semibold">
            Payment status:{" "}
            <span className="ml-2 bg-green-600 px-2 py-1 rounded text-white">
              {userData.payment?.status}
            </span>
          </p>
        </div>
      ) : (
        <p className="text-xl m-4">No Payment Information avaliable yet.</p>
      )}

      {userData?.voucher ? (
        <div className="shadow m-4 rounded p-3 capitalize flex flex-col gap-2">
          <p className="text-lg text-gray-600 font-semibold">
            Voucher Details:
          </p>
          <p className="font-semibold">
            Applied For:{" "}
            <span className="text-gray-600 ml-2">
              {userData.voucher?.appliedFor}
            </span>
          </p>
          <p className="font-semibold">
            Categories:{" "}
            <span className="text-gray-600 ml-2">
              {userData.voucher?.categories[0]}
            </span>
          </p>
          <p className="font-semibold">
            Voucher No:{" "}
            <span className="text-gray-600 ml-2">
              {userData.voucher?.voucherNo}
            </span>
          </p>
          <img
            src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.voucher?.voucherImage}`}
            className="w-[15rem] cursor-pointer"
            title="Click to View Larger"
            onClick={() => {
              setImageModal(true);
              setModalImgUrl(
                `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.voucher?.voucherImage}`
              );
            }}
          />
        </div>
      ) : (
        <p className="text-xl m-4">No Voucher Information avaliable yet.</p>
      )}

      {userData?.personalInfo ? (
        <div className="shadow m-4 rounded p-3">
          <p className="text-lg text-gray-600 font-semibold mb-2">
            Personal Information:
          </p>
          <div className="grid lg:grid-cols-5 grid-cols-1 gap-x-2 gap-y-3 capitalize">
            <p className="font-semibold">
              FullName:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.user.fullName}
              </span>
            </p>
            <p className="font-semibold">
              Email:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.user.email}
              </span>
            </p>
            <p className="font-semibold">
              Phone:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.user.phone}
              </span>
            </p>
            <p className="font-semibold">
              Father's Name:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.father_name}
              </span>
            </p>
            <p className="font-semibold">
              Mother's Name:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.mother_name}
              </span>
            </p>
            <p className="font-semibold">
              Gender:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.gender}
              </span>
            </p>
            <p className="font-semibold">
              Metropolitan:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.metropolitan}
              </span>
            </p>
            <p className="font-semibold">
              Province:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.province}
              </span>
            </p>
            <p className="font-semibold">
              District:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.district}
              </span>
            </p>
            <p className="font-semibold">
              Tole:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.tole}
              </span>
            </p>
            <p className="font-semibold">
              Ward Number:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.ward_number}
              </span>
            </p>
            <p className="font-semibold">
              Citizenship Number:{" "}
              <span className="text-gray-600 ml-2">
                {userData.personalInfo.citizenship_number}
              </span>
            </p>
            <div>
              <p className="font-semibold">Citizenship Image:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.citizenship_image}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.citizenship_image}`
                  );
                }}
              />
            </div>

            <div>
              <p className="font-semibold">Photo:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.photo}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.photo}`
                  );
                }}
              />
            </div>

            <div>
              <p className="font-semibold">Signature Image:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.signature_image}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.signature_image}`
                  );
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <p className="text-xl m-4">No Personal Information avaliable yet.</p>
      )}

      {userData?.academicInfo ? (
        <div className="shadow m-4 rounded p-3">
          <p className="text-lg text-gray-600 font-semibold mb-2">
            Academic Information:
          </p>
          <div className="grid lg:grid-cols-5 grid-cols-1 gap-x-2 gap-y-3 capitalize">
            <p className="font-semibold">
              SEE School Name:{" "}
              <span className="text-gray-600 ml-2">
                {userData.academicInfo.seeSchoolName}
              </span>
            </p>
            <p className="font-semibold">
              SEE Faculty:{" "}
              <span className="text-gray-600 ml-2">
                {userData.academicInfo.seeFaculty}
              </span>
            </p>
            <p className="font-semibold">
              SEE GPA:{" "}
              <span className="text-gray-600 ml-2">
                {userData.academicInfo.seeGpa}
              </span>
            </p>
            <p className="font-semibold">
              SEE Percentage:{" "}
              <span className="text-gray-600 ml-2">
                {userData.academicInfo.seePercentage}
              </span>
            </p>
            <div>
              <p className="font-semibold">SEE Character Certificate Image:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.seeCharacterCertificateImage}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.seeCharacterCertificateImage}`
                  );
                }}
              />
            </div>
            <div>
              <p className="font-semibold">SEE Marksheet Image:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.seeMarksheetImage}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.seeMarksheetImage}`
                  );
                }}
              />
            </div>
            <div className="col-span-5 flex justify-between my-3">
              <p className="font-semibold">
                Plus 2 College Name:{" "}
                <span className="text-gray-600 ml-2">
                  {userData.academicInfo.plus2CollegeName}
                </span>
              </p>
              <p className="font-semibold">
                Plus 2 Faculty:{" "}
                <span className="text-gray-600 ml-2">
                  {userData.academicInfo.plus2Faculty}
                </span>
              </p>
              <p className="font-semibold">
                Plus 2 GPA:{" "}
                <span className="text-gray-600 ml-2">
                  {userData.academicInfo.plus2Gpa}
                </span>
              </p>
              <p className="font-semibold">
                Plus 2 Percentage:{" "}
                <span className="text-gray-600 ml-2">
                  {userData.academicInfo.plus2Percentage}
                </span>
              </p>
            </div>
            <div>
              <p className="font-semibold">
                Plus 2 Character Certificate Image:
              </p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2CharacterCertificateImage}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2CharacterCertificateImage}`
                  );
                }}
              />
            </div>
            <div>
              <p className="font-semibold">Plus 2 Migration Image:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2MigrationImage}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2MigrationImage}`
                  );
                }}
              />
            </div>
            <div>
              <p className="font-semibold">Plus 2 Transcript Image:</p>
              <img
                src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2TranscriptImage}`}
                className="w-[15rem] cursor-pointer"
                title="Click to View Larger"
                onClick={() => {
                  setImageModal(true);
                  setModalImgUrl(
                    `${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.academicInfo.plus2TranscriptImage}`
                  );
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <p className="text-xl m-4">No Academic Information avaliable yet.</p>
      )}
    </div>
  );
};

export default UserData;
