import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import Swal from "sweetalert2";

const VoucherForm = ({ handleNextPersonal }) => {
  const [appliedFor, setAppliedFor] = useState("B.Sc. Agriculture Hons.");
  const [categories, setCategories] = useState([]);
  const [voucherImage, setVoucherImage] = useState(null);

  const categoryOptions = [
    "मेरीट","दलित","आदिवासी जनजाति","थारु","मुस्लिम","आर्थिक रुपमा विपन्न खसआर्य","अपाङ्ग","शहिद परिवार","द्वन्द पिडित","छात्रा","स्टाफ कोटा (कर्मचारी)","स्थानिय कोटा","पूर्ण शुल्क UDF/CD"
  ];

  const handleCategoryChange = (option) => {
    if (categories.includes(option)) {
      setCategories(categories.filter((cat) => cat !== option));
    } else {
      setCategories([...categories, option]);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setVoucherImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "user-token": Cookies.get("user-token"),
      },
    };
    const formDataToSend = new FormData();
    formDataToSend.append("appliedFor", appliedFor);
    formDataToSend.append("categories", categories);
    formDataToSend.append("voucherImage", voucherImage);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/add/voucher_info`,
        formDataToSend,
        config
      );
      Swal.fire("Success", response.data.message, "success");
      handleNextPersonal();
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while submitting the form",
        "error"
      );
    }
  };

  return (
    <div className="w-full max-w-xl mx-auto mt-5 lg:p-4 p-0">
      <p className="text-sm py-2 px-2 text-green-700 rounded my-2">
        Your payment has been successfully completed.Now you can proceed further
        for form submission.
      </p>
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded lg:px-8 px-4 py-6"
      >
        <div className="mb-4">
          <label
            htmlFor="appliedFor"
            className="block text-gray-700 text-xs font-bold mb-2"
          >
            Applied For
          </label>
          <input
            type="text"
            name="appliedFor"
            id="appliedFor"
            value={appliedFor}
            onChange={(e) => setAppliedFor(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-xs font-bold mb-2">
            Category
          </label>
          <div className="flex flex-wrap gap-3">
            {categoryOptions.map((option, index) => (
              <label key={index} className="mr-4">
                <input
                  type="checkbox"
                  onChange={() => handleCategoryChange(option)}
                  checked={categories.includes(option)}
                  className=""
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="voucherImage"
            className="block text-gray-700 text-xs font-bold mb-2"
          >
            Upload the receipt of the online payment or upload the
            screenshot of the payment done by khalti.
          </label>
          <input
            type="file"  
            name="voucherImage"
            id="voucherImage"
            accept="image/*"
            onChange={handleImageChange}
          />
          {voucherImage && (
            <img
              src={URL.createObjectURL(voucherImage)}
              alt="Voucher Image Preview"
              className="mt-2 max-w-xs"
            />
          )}
        </div>

        <div className="mb-4 mt-3">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default VoucherForm;
