import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
const PaymentSuccessPage = () => {
  const search = useLocation().search;
  const oid = new URLSearchParams(search).get("oid");
  const amt = new URLSearchParams(search).get("amt");
  const ref = new URLSearchParams(search).get("refId");
  const postPaymentStatus = async () => {
    const token = Cookies.get("user-token") ? Cookies.get("user-token") : null;
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/status-update/esewa`,
      {
        oid,
        amt,
        ref,
      },
      {
        headers: {
          "user-token": token,
        },
      }
    );
    if (response.data.message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: response.data.message,
      });
    }
  };
  postPaymentStatus();
  window.location.replace("/user/dashboard");
  // useEffect(() => {

  // }, []);
  return (
    <div className="container mx-auto">
      <p className="text-center py-6 ">Payment Success ...</p>
    </div>
  );
};

export default PaymentSuccessPage;
