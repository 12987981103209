import React, { useState, useEffect } from "react";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import axios from "axios";
import Cookies from "js-cookie";
import Loader from "../loader";
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
const AdmitCard = ({ userId }) => {
  const [userData, setUserData] = useState(null);
  const [userStatus, setUserStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [rollNo, setRollNo] = useState("");
  const convertToPDF = () => {
    setLoading(true);
    generatePDF(getTargetElement, options);
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = Cookies.get("user-detail")
        ? JSON.parse(Cookies.get("user-detail"))
        : null;
      setUserStatus(user.status);
     
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/get/user-details/${userId}`
      );
      setUserData(response.data);
    };
    fetchData();
  }, []);

  const options = {
    method: "open",
    resolution: Resolution.MEDIUM,
    page: {
      margin: Margin.SMALL,
      format: "letter",
      orientation: "potrait",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };
  const getTargetElement = () => document.getElementById("content-id");

  const exportAdmitCard1 = async () => {
    try {
      const user = Cookies.get("user-detail")
        ? JSON.parse(Cookies.get("user-detail"))
        : null;

      if (!user) {
        console.error("User details not found.");
        return;
      }

      const generateResponse = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/generate-admit-card/${userId}`
      );

      if (!generateResponse) {
        console.error("Admit card generation failed.");
        return;
      }

      const getAdmitResponse = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/get-admit-card/${userId}`,
        { responseType: "blob" }
      );

      if (getAdmitResponse) {
        console.log(getAdmitResponse);
        const { data } = getAdmitResponse;

        saveAs(data, "myAdmitCard.pdf");
      } else {
        console.error("Failed to fetch admit card.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <div>
        <button
          className="bg-green-600 text-white px-2 py-1 rounded m-2"
          onClick={() => {
            convertToPDF();
          }}
        >
          Generate Admit Card
        </button>
        <p className="m-2 tracking-wider text-xl underline">
          कृपया ठीक पीडीएफ फर्म्याटको लागि ल्यापटप वा कम्प्युटर बाट प्रवेश पत्र
          डाउनलोड गर्नुहोस्।
        </p>
        <div
          className="lg:w-[60%] w-full mx-auto shadow mt-4"
          style={{ color: "black" }}
          id="content-id"
        >
          <div className="p-4">
            <div className="flex lg:gap-[3rem] gap-2">
              <div>
                <img
                  src="/logo.png"
                  alt="Madhesh Agriculture University"
                  className="lg:w-[10rem] w-[5rem] lg:h-[10rem] rounded"
                />
              </div>
              <div className="flex flex-col lg:justify-center justify-center items-center lg:pb-0 pb-3">
                <h3 className="lg:text-xl text-sm font-semibold text-red-600">
                  MAU
                </h3>
                <h3 className="lg:text-xl text-sm font-semibold text-red-600">
                  Madhesh Agriculture University
                </h3>
                <h1 className="lg:text-2xl text-base text-blue-600 font-semibold mt-2">
                  Rajbiraj-03, Madhesh Pradesh, Nepal
                </h1>
                <p className="lg:text-xl text-sm font-normal text-red-600 mt-2">
                  Entrance Examination - 2080
                </p>
                <h1 className="lg:text-xl text-sm font-bold text-red-600">
                  ADMIT CARD
                </h1>
              </div>
            </div>
            {userData && (
              <div className="flex justify-between">
                <div className="flex flex-col gap-3 mt-6">
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Exam Roll No: <span className="lg:ml-3 ml-0">{userData?.personalInfo?.user?.rollNo}</span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Student Name :{" "}
                    <span className="lg:ml-3 ml-0 capitalize">
                      {" "}
                      {userData?.personalInfo?.user?.fullName}
                    </span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Program Name : <span className="ml-3">B.Sc.Ag. Hons.</span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Gender:{" "}
                    <span className="lg:ml-3 ml-0">
                      {" "}
                      {userData?.personalInfo?.gender}
                    </span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    District:{" "}
                    <span className="lg:ml-3 ml-0">
                      {" "}
                      {userData?.personalInfo?.district}
                    </span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    IdentificationDoc:{" "}
                    <span className="lg:ml-3 ml-0">
                      {" "}
                      {userData?.personalInfo?.citizenship_number}
                    </span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Nationality: <span className="lg:ml-3 ml-0">Nepalese</span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Exam Date :{" "}
                    <span className="lg:ml-3 ml-0">2080/12/30 (2:00 PM)</span>
                  </p>
                  <p className="font-normal lg:font-semibold text-gray-800">
                    Exam Center :{" "}
                    <span className="lg:ml-3 ml-0">
                      K.A. Madhyamik Bidhyalaya, Rajbiraj-03
                    </span>
                  </p>
                </div>
                <div>
                  <div>
                    <img
                      src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.photo}`}
                      className="lg:w-[10rem] w-[10rem] h-[10rem] lg:h-[10rem] mt-8 cursor-pointer"
                      alt={userData?.personalInfo?.user?.fullName}
                    />
                    <img
                      src="/rs.png"
                      alt="Madhesh Agriculture University"
                      className="w-[8rem] rounded mt-[-2rem] h-[6rem]"
                    />
                    <p className="text-blue-600 mt-[-2rem]">
                      Authorized Signature
                    </p>
                  </div>
                  <div className="mt-4">
                    <img
                      src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.signature_image}`}
                      className="w-[15rem] cursor-pointer h-[6rem]"
                      alt={userData?.personalInfo?.user?.fullName}
                    />
                    <p className="mt-2 text-blue-600">Candidate's Signature</p>
                  </div>
                </div>
              </div>
            )}
            <div>
              <img src="/rule.jpeg" className="w-full mt-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmitCard;
