import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
const Header = () => {
  const [user, setUser] = useState({});
  const [active, setActive] = useState(false);
  const [menuHeight, setMenuHeight] = useState("0px");
  const menuBar = useRef(null);
  useEffect(() => {
    const userDetail = Cookies.get("user-detail")
      ? Cookies.get("user-detail")
      : null;
    setUser(JSON.parse(userDetail));
  }, []);
  const logout = () => {
    Cookies.remove("user-detail");
    Cookies.remove("user-token");
    window.location.replace("/");
  };
  const location = useLocation();
  const toggleMenu = () => {
    setActive((prevActive) => !prevActive);
    setMenuHeight((prevHeight) =>
      prevHeight === "0px" ? `${menuBar.current.scrollHeight}px` : "0px"
    );
  };

  return (
    <>
      <div className="lg:block hidden mx-auto bg-green-900 text-gray-100">
        <div className="px-[5em]">
          <div className="flex lg:flex-row flex-col justify-between items-center ">
            <div className="flex flex-row gap-3">
              <p className="text-sm font-normal tracking-wider">
                <i className="fas fa-phone mr-2"></i>
                <a className="font-sans" href="tel:+031-590555">
                031-590555
                </a>
              </p>
              <span className="text-sm font-normal tracking-wider">
                <i className="fas fa-envelope mr-2"></i>
                <a href="mailto:entrance@mau.edu.np">entrance@mau.edu.np</a>
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <div>
                <a href="#" rel="noopener noreferrer">
                  <button title="whatsapp">
                    <i className="fab fa-whatsapp text-gray-100"></i>
                  </button>
                </a>
              </div>
              <div>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <button title="viber">
                    <i className="fab fa-viber text-gray-100"></i>
                  </button>
                </a>
              </div>
              <a target="_blank" href="#">
                <button title="instagram">
                  <i className="fab fa-instagram text-gray-100"></i>
                </button>
              </a>
              <a href="https://www.facebook.com/" rel="noopener noreferrer">
                <button className="facebook" title="facebook">
                  <i className="fab fa-facebook text-gray-100"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 shadow py-1 w-full sticky top-0 z-[999]">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between items-center gap-4 px-2">
            <Link to="/">
              <img src="/logo.png" alt="mau.png" className="w-[25%]" />
            </Link>
            <div className="">
              <ul className="flex gap-4">
                {user ? (
                  <>
                    <li>
                      <div className="flex space-x-3 items-center mt-[4px]">
                        <div className="flex-none flex justify-center">
                          <div className="w-8 h-8 flex ">
                            {/* <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShta_GXR2xdnsxSzj_GTcJHcNykjVKrCBrZ9qouUl0usuJWG2Rpr_PbTDu3sA9auNUH64&usqp=CAU"
                          alt="profile"
                          className="shadow rounded-full object-cover"
                        /> */}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="from-green-100 via-green-300 to-green-500 bg-gradient-to-br  flex justify-center items-center">
                        <div className="relative">
                          <button className="bg-green-500 p-2 font-bold text-gray-100 rounded-md peer focus:bg-green-400 focus:text-gray-200 transition-all duration-200  ">
                            Welcome {user.fullName}
                          </button>
                          <div
                            className=' w-80 absolute top-5 z-10 right-0
		                     after:content-[""] after:inline-block after:absolute after:top-0 after:bg-white/40
	                      	after:w-full after:h-full after:-z-20 after:blur-[2px] after:rounded-lg
                          peer-focus:top-12 peer-focus:opacity-100 peer-focus:visible 
                           transition-all duration-300 invisible opacity-0'
                          >
                            <ul className="py-6 px-3 flex flex-col gap-3">
                              <li className="cursor-pointer bg-green-300 p-3 rounded-md hover:opacity-90 text-white">
                                <Link to="/user/dashboard">View Account</Link>
                              </li>
                              <li
                                onClick={logout}
                                className="cursor-pointer bg-green-300 p-3 rounded-md hover:opacity-90 text-white"
                              >
                                Logout
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <button className="bg-green-600 text-white lg:px-4 px-3 py-[0.3rem] lg:py-2 rounded lg:hidden block">
                        <Link to="/signin">
                          <i className="fas fa-sign-in-alt"></i>
                        </Link>
                      </button>
                      <button className="bg-green-600 text-white lg:px-4 px-3 py-[0.3rem] lg:py-2 rounded lg:block hidden">
                        <Link to="/signin">
                          {" "}
                          <i className="fas fa-sign-in-alt mr-1"></i> Login
                        </Link>
                      </button>
                    </li>
                    <li>
                      <button className="bg-green-600 text-white lg:px-4 px-3 lg:py-2 py-[0.3rem] rounded lg:hidden block">
                        <Link to="/signup">
                          <i className="fas fa-user-plus"></i>
                        </Link>
                      </button>
                      <button className="bg-green-600 text-white lg:px-4 px-3 lg:py-2 py-[0.3rem] rounded lg:block hidden">
                        <Link to="/signup">
                          <i className="fas fa-user-plus mr-1"></i> Register
                        </Link>
                      </button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
