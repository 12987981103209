import React, { useState, useEffect } from "react";
import axios from "axios";
const PaymentStatus = ({ userId }) => {
  const [paymentStatus, setPaymentStatus] = useState(false);
  const fetchPaymentStatus = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/admin/check/${userId}`
    );
    if (response.data) {
      setPaymentStatus(response.data.payment);
    }
  };

  useEffect(() => {
    fetchPaymentStatus();
  }, [userId]);
  return (
    <div>
      {paymentStatus ? (
        <p className="text-green-600">Completed</p>
      ) : (
        <p className="text-red-600">Not Completed</p>
      )}
    </div>
  );
};

export default PaymentStatus;
