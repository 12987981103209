import React from "react";

const Thankyou = () => {
  return (
    <div>
      <div className="flex items-center justify-center min-h-screen from-gray-800 via-greeen-300 to-blue-500 bg-gradient-to-br">
        <div className="w-full max-w-xl px-4 py-4 mx-auto bg-white rounded-lg shadow-xl">
          <div className="mx-auto space-y-6">
            <div className="py-2 px-2 mx-auto">
              <div className="mx-auto max-w-screen-sm text-center">
                <i className="fa fa-check-square text-5xl text-green-600"></i>
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-xl text-gray-600">
                  success
                </h1>
                <p className="mb-4 text-xl tracking-tight font-bold text-gray-600">
                  Thank you for submitting your form!
                </p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400 text-justify">
                  Your form submission has been received and is currently being
                  reviewed by our administration team. We appreciate your
                  interest and will carefully verify the information you
                  provided. Once the verification process is complete, we will
                  notify you of the approval status via email. Please keep an
                  eye on your inbox for updates. If you have any questions or
                  need further assistance, feel free to contact our support team
                  at [Your Contact Email or Phone Number]. We're here to help!
                  Thank you for choosing our services, and we look forward to
                  assisting you further.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
