import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import userAuth from "../../libs/userAuth";
const UserLayout = (props) => {
  const [active, setActive] = useState(false);
  const [menuHeight, setMenuHeight] = useState("0px");
  const menuBar = useRef(null);
  const [user, setUser] = useState("");

  useEffect(() => {
    const user = JSON.parse(Cookies.get("user-detail"));
    setUser(user);
  }, []);
  useEffect(() => {
    userAuth();
  }, []);

  const logout = () => {
    Cookies.remove("user-detail");
    Cookies.remove("user-token");
    Cookies.remove("apiKey");
    window.location.replace("/");
  };

  const toggleMenu = () => {
    setActive((prevActive) => !prevActive);
    setMenuHeight((prevHeight) =>
      prevHeight === "0px" ? `${menuBar.current.scrollHeight}px` : "0px"
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 flex lg:flex-row flex-col  w-full">
      <div className="lg:hidden block py-2 sticky top-0 w-full z-10 bg-gray-50">
        <div className="flex flex-row justify-between gap-2 p-2">
          <p className="text-xl font-semibold capitalize text-gray-600">
            <Link to="/user/dashboard">Mau-Entrance</Link>
          </p>
          <p
            className="font-semibold text-xl cursor-pointer capitalize text-gray-600"
            onClick={toggleMenu}
          >
            {active ? "close" : "menu"}
          </p>
        </div>
      </div>
      <div
        ref={menuBar}
        style={{
          maxHeight: `${menuHeight}`,
          transition: "max-height 0.6s ease",
        }}
        className="overflow-hidden px-2 lg:hidden block"
      >
        <ul className="mt-6 space-y-2 tracking-wide">
          <li className="min-w-max">
            <Link
              to="/user/dashboard"
              aria-label="dashboard"
              className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-cyan-400 px-4 py-3 text-white"
            >
              <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                  className="fill-current text-cyan-400 dark:fill-slate-600"
                ></path>
                <path
                  d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                  className="fill-current text-cyan-200 group-hover:text-cyan-300"
                ></path>
                <path
                  d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                  className="fill-current group-hover:text-sky-300"
                ></path>
              </svg>
              <span className="-mr-1 font-medium">Dashboard</span>
            </Link>
          </li>
          <li className="min-w-max">
            <Link
              to="/user/details"
              aria-label="dashboard"
              className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-cyan-400 px-4 py-3 text-white"
            >
              <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                  className="fill-current text-cyan-400 dark:fill-slate-600"
                ></path>
                <path
                  d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                  className="fill-current text-cyan-200 group-hover:text-cyan-300"
                ></path>
                <path
                  d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                  className="fill-current group-hover:text-sky-300"
                ></path>
              </svg>
              <span className="-mr-1 font-medium">My form</span>
            </Link>
          </li>
          <li className="min-w-max">
            <div onClick={logout} className="w-max mb-6">
              <Link className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 group-hover:fill-cyan-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="group-hover:text-gray-700">Logout</span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <div className="sidebar h-screen w-[15%] lg:block hidden overflow-hidden border-r hover:w-56 hover:bg-white hover:shadow-lg transition-all duration-200 ">
        <div className="flex h-screen flex-col justify-between pt-2 pb-6">
          <div>
            <div className="p-2.5 text-center">
              <p className="text-lg font-bold text-center text-teal-500">
                Mau-Entrance
              </p>
              <p className="py-2 text-base text-gray-600 border-b-4 capitalize">
                {user.fullName}
              </p>
            </div>
            <ul className="mt-6 space-y-2 tracking-wide">
              <li className="min-w-max">
                <Link
                  to="/user/dashboard"
                  aria-label="dashboard"
                  className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-cyan-400 px-4 py-3 text-white"
                >
                  <svg
                    className="-ml-1 h-6 w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      className="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      className="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      className="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span className="-mr-1 font-medium">Dashboard</span>
                </Link>
              </li>
              <li className="min-w-max">
                <Link
                  to="/user/details"
                  aria-label="dashboard"
                  className="relative flex items-center space-x-4 bg-gradient-to-r from-sky-600 to-cyan-400 px-4 py-3 text-white"
                >
                  <svg
                    className="-ml-1 h-6 w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                      className="fill-current text-cyan-400 dark:fill-slate-600"
                    ></path>
                    <path
                      d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                      className="fill-current text-cyan-200 group-hover:text-cyan-300"
                    ></path>
                    <path
                      d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                      className="fill-current group-hover:text-sky-300"
                    ></path>
                  </svg>
                  <span className="-mr-1 font-medium">My Form</span>
                </Link>
              </li>
            </ul>
          </div>
          <div onClick={logout} className="w-max -mb-3">
            <Link className="group flex items-center space-x-4 rounded-md px-4 py-3 text-gray-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 group-hover:fill-cyan-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="group-hover:text-gray-700 text-lg font-bold">
                Logout
              </span>
            </Link>
          </div>
        </div>
      </div>
      <main className="lg:w-[85%] w-[100%] h-screen overflow-y-auto overflow-x-hidden ">
        {props.children}
      </main>
    </div>
  );
};

export default UserLayout;
