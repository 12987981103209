import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../loader";
const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const formData = {
    email,
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/user/forgot-password`,
        formData
      );
      if (response.data.error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "question",
          title: response.data.error,
        });
        setLoading(false);
      }

      if (response.data.message) {
        Swal.fire("Success", response.data.message, "success");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex items-center justify-center bg-gray-200  h-screen text-gray-600">
      {loading && <Loader />}
      <div className="bg-white p-8 rounded shadow-md w-[50%]">
        <h2 className="text-2xl font-semibold mb-2">Reset Your Password</h2>
        <p>
          Please enter your registered email here. A verification link will be
          sent on your email to confirm.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 mt-3">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
              placeholder="Enter your email address"
              required
            />
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Verify Email Address
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
