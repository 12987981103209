import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Dreadcrumb from "../dreadcrumb";

const EditPersonalInfoForm = ({ closeEdit, userData }) => {
  const [father_name, setFatherName] = useState(
    userData.personalInfo.father_name
  );
  const [mother_name, setMotherName] = useState(
    userData.personalInfo.mother_name
  );
  const [province, setProvince] = useState(userData.personalInfo.province);
  const [district, setDistrict] = useState(userData.personalInfo.district);
  const [metropolitan, setMetropolitan] = useState(
    userData.personalInfo.metropolitan
  );
  const [ward_number, setWardNumber] = useState(userData.personalInfo.district);
  const [tole, setTole] = useState(userData.personalInfo.tole);
  const [gender, setGender] = useState(userData.personalInfo.gender);
  const [photo, setPhoto] = useState(null);
  const [signature_image, setSignatureImage] = useState(null);
  const [citizenship_number, setCitizenshipNumber] = useState(
    userData.personalInfo.citizenship_number
  );
  const [citizenship_image, setCitizenshipImage] = useState(null);
  const [userName, setUserName] = useState("");
  const user = Cookies.get("user-detail")
    ? JSON.parse(Cookies.get("user-detail"))
    : null;
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      // Handle file input
      const file = files[0];
      if (name === "photo") {
        setPhoto(file);
      } else if (name === "signature_image") {
        setSignatureImage(file);
      } else if (name === "citizenship_image") {
        setCitizenshipImage(file);
      }
    } else {
      // Handle text input
      if (name === "father_name") {
        setFatherName(value);
      } else if (name === "mother_name") {
        setMotherName(value);
      } else if (name === "userName") {
        setUserName(value);
      } else if (name === "province") {
        setProvince(value);
      } else if (name === "district") {
        setDistrict(value);
      } else if (name === "metropolitan") {
        setMetropolitan(value);
      } else if (name === "ward_number") {
        setWardNumber(value);
      } else if (name === "tole") {
        setTole(value);
      } else if (name === "gender") {
        setGender(value);
      } else if (name === "citizenship_number") {
        setCitizenshipNumber(value);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "user-token": Cookies.get("user-token"),
      },
    };
    const formDataToSend = new FormData();
    formDataToSend.append("father_name", father_name);
    formDataToSend.append("userName", userName);
    formDataToSend.append("mother_name", mother_name);
    formDataToSend.append("province", province);
    formDataToSend.append("district", district);
    formDataToSend.append("metropolitan", metropolitan);
    formDataToSend.append("ward_number", ward_number);
    formDataToSend.append("tole", tole);
    formDataToSend.append("gender", gender);
    formDataToSend.append("citizenship_number", citizenship_number);
    formDataToSend.append("photo", photo);
    formDataToSend.append("signature_image", signature_image);
    formDataToSend.append("citizenship_image", citizenship_image);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/form/personal_info/update`,
        formDataToSend,
        config
      );
      Swal.fire("Success", response.data.message, "success");
    } catch (error) {
      Swal.fire(
        "Error",
        "An error occurred while submitting the form",
        "error"
      );
    }
  };

  return (
    <div className="w-full mx-auto fixed top-0 right-0 h-[100vh] overflow-y-auto bg-white">
      <Dreadcrumb page="Edit Personal Information" />
      <div className="flex justify-end p-2">
        <button
          onClick={closeEdit}
          className="bg-red-600 text-white rounded px-2 py-1"
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className=" shadow-md rounded px-8 pt-6 pb-8 mb-4 gap-4"
      >
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-2 my-2">
          <div className="mb-4">
            <label
              htmlFor="userName"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Full Name*
            </label>
            <input
              type="text"
              name="userName"
              id=""
              value={user.fullName}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Gender*
            </label>
            <div className="flex">
              <label className="mr-4">
                <input
                  type="radio"
                  name="gender"
                  value="Male"
                  onChange={handleInputChange}
                  required
                  checked={gender === "Male"}
                />
                Male
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={handleInputChange}
                  required
                  checked={gender === "Female"}
                />
                Female
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="father_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Father's Name*
            </label>
            <input
              type="text"
              name="father_name"
              id="father_name"
              value={father_name}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="mother_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Mother's Name*
            </label>
            <input
              type="text"
              name="mother_name"
              id="mother_name"
              value={mother_name}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-4 grid-cols-1 gap-2 my-2">
          <div className="mb-4">
            <label
              htmlFor="province"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Province*
            </label>
            <select
              name="province"
              id="province"
              value={province}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="Province 1">select your province</option>
              <option value="Province 1">Province 1</option>
              <option value="Province 2">Province 2</option>
              <option value="Bagmati Province">Bagmati Province</option>
              <option value="Gandaki Province">Gandaki Province</option>
              <option value="Lumbini Province">Lumbini Province</option>
              <option value="Karnali Province">Karnali Province</option>
              <option value="Sudurpashchim Province">
                Sudurpashchim Province
              </option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="district"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              District*
            </label>
            <input
              type="text"
              name="district"
              id="district"
              value={district}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="metropolitan"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Metropolitan/sub-metropolitan*
            </label>
            <input
              type="text"
              name="metropolitan"
              id="metropolitan"
              value={metropolitan}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="ward_number"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Ward Number
            </label>
            <input
              type="text"
              name="ward_number"
              id="ward_number"
              value={ward_number}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="tole"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Tole
            </label>
            <input
              type="text"
              name="tole"
              id="tole"
              value={tole}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 my-2">
          <div className="mb-4">
            <label
              htmlFor="photo"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Passport Size Photo* {" (600 x 600)"}
            </label>
            <input
              type="file"
              name="photo"
              id="photo"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.photo}`}
              className="w-[15rem] cursor-pointer"
            />

            {photo && (
              <img
                src={URL.createObjectURL(photo)}
                alt="Photo Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="signatureImage"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Signature Image*
            </label>
            <input
              type="file"
              name="signature_image"
              id="signature_image"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.signature_image}`}
              className="w-[15rem] cursor-pointer"
            />
            {signature_image && (
              <img
                src={URL.createObjectURL(signature_image)}
                alt="Signature Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="citizenship_number"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Citizenship Number*
            </label>
            <input
              type="text"
              name="citizenship_number"
              id="citizenship_number"
              value={citizenship_number}
              onChange={handleInputChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="citizenship_image"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Citizenship Image*
            </label>
            <input
              type="file"
              name="citizenship_image"
              id="citizenship_image"
              accept="image/*"
              onChange={handleInputChange}
            />
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/uploads/${userData.personalInfo.citizenship_image}`}
              className="w-[15rem] cursor-pointer"
            />
            {citizenship_image && (
              <img
                src={URL.createObjectURL(citizenship_image)}
                alt="Citizenship Image Preview"
                className="mt-2 max-w-xs"
              />
            )}
          </div>
        </div>

        <div className="mb-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPersonalInfoForm;
