import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./pages/signup";
import Login from "./pages/login";
import AdminDashboard from "./pages/admin/dashboard";
import UserDashboard from "./pages/user/dashboard";
import ActivateUser from "./pages/activateUser";
import React, { useEffect, useState } from "react";
import AdminLayout from "./components/admin/adminLayout";
import UserLayout from "./components/user/userLayout";
import UserListPage from "./pages/admin/user_list";
import SiteLayout from "./components/siteLayout";
import Thankyou from "./pages/user/thank-you";
import UserData from "./components/user/userData";
import PaymentSuccessPage from "./components/user/payment_SuccessPage";
import ForgotPasswordForm from "./components/common/forgotPassword";
import ResetPasswordForm from "./components/common/resetPasswordForm";
import AdmitCard from "./components/user/admitCard";

function App() {
  const [currentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <Router>
      {/* {currentUrl === `${process.env.REACT_APP_CLIENT_URL}` && <Header />} */}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <SiteLayout>
              <Login />
            </SiteLayout>
          }
        />
      </Routes>

      <Routes>
        <Route
          path="/signup"
          element={
            <SiteLayout>
              <Signup />
            </SiteLayout>
          }
        />
      </Routes>

      <Routes>
        <Route
          path="/signin"
          element={
            <SiteLayout>
              <Login />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/dashboard"
          element={
            <AdminLayout>
              <AdminDashboard />
            </AdminLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/admin/dashboard/user-list"
          element={
            <AdminLayout>
              <UserListPage />
            </AdminLayout>
          }
        />
      </Routes>

      <Routes>
        <Route
          path="/user/dashboard"
          element={
            <UserLayout>
              <UserDashboard />
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/user/admit-card"
          element={
            <UserLayout>
              <AdmitCard />
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/user/thank-you"
          element={
            <UserLayout>
              <Thankyou />
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/user/details"
          element={
            <UserLayout>
              <UserData />
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/activate-account"
          element={
            <SiteLayout>
              <ActivateUser />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/forgot-password"
          element={
            <SiteLayout>
              <ForgotPasswordForm />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/reset-password"
          element={
            <SiteLayout>
              <ResetPasswordForm />
            </SiteLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/success"
          element={
            <UserLayout>
              <div>
                <PaymentSuccessPage />
              </div>
            </UserLayout>
          }
        />
      </Routes>
      <Routes>
        <Route
          path="/failed"
          element={
            <UserLayout>
              <div>
                <p className="py-4 text-xl font-semibold capitalize px-5">
                  Failed page is in development
                </p>
              </div>
            </UserLayout>
          }
        />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
