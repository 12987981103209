import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import userAuth from "../../libs/userAuth";
import Dreadcrumb from "../../components/dreadcrumb";
import PersonalInfoForm from "../../components/forms/personalInfo";
import AcademicInfoForm from "../../components/forms/academicInfo";
import VoucherForm from "../../components/forms/voucherForm";
import AccountInfo from "../../components/forms/accountInfo";
import UserData from "../../components/user/userData";
import axios from "axios";
const Dashboard = () => {
  const [user, setUser] = useState("");
  const currentHour = new Date().getHours();
  const [currentTab, setCurrentTab] = useState(0);
  const [voucherForm, setVoucherForm] = useState(true);
  const [nextPersonal, setNextPersonal] = useState(false);
  const [nextAcademic, setNextAcademic] = useState(false);
  const [isVoucher, setIsVoucher] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [khaltiStatus, setKhaltiStatus] = useState("Pending");
  const [isPersonal, setIsPersonal] = useState(false);
  const [isAcademic, setIsAcademic] = useState(false);
  const changeTab = (n) => {
    setCurrentTab(n);
  };
  useEffect(() => {
    userAuth();
  }, []);

  const handleNextPersonal = () => {
    setVoucherForm(false);
    changeTab(1);
    setNextPersonal(true);
  };
  const handleNextAcademic = () => {
    setNextAcademic(true);
    setNextPersonal(false);
    setVoucherForm(false);
    changeTab(2);
  };
  useEffect(() => {
    const user = JSON.parse(Cookies.get("user-detail"));
    setUser(user);
  }, []);

  const checkVouher = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/voucher`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data.status == true) {
      setIsVoucher(response.data.status);
      setVoucherForm(false);
      changeTab(1);
      setNextPersonal(true);
    }
  };

  const checkAcademic = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/check-academic-info`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data) {
      setIsAcademic(response.data.status);
    }
  };

  const checkPersonal = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/check-personal-info`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data.status == true) {
      setIsPersonal(response.data.status);
      setNextAcademic(true);
      setNextPersonal(false);
      setVoucherForm(false);
      changeTab(2);
    } else {
      setIsPersonal(response.data.status);
    }
  };

  const fetchKhaltiPayment = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/check-khalti`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data) {
      setKhaltiStatus(response.data);
    }
  };

  const fetchPaymentStatus = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/form/payment/check`,
      {
        headers: {
          "user-token": Cookies.get("user-token"),
        },
      }
    );
    if (response.data) {
      setPaymentStatus(response.data.payment);
    }
  };

  useEffect(() => {
    const user = JSON.parse(Cookies.get("user-detail"));
    setUser(user);
    fetchPaymentStatus();
    // fetchKhaltiPayment();
    checkVouher();
    checkAcademic();
    checkPersonal();
  }, []);

  return (
    <div className="w-full dark:bg-slate-100 h-[100%] overflow-y-scroll relative">
       
      {!isAcademic ? (
        <div className="p-4">
          <Dreadcrumb page="dashboard" />
          {currentTab === 0 && voucherForm ? (
            <div className="mt-4">
              <div className="flex gap-4 justify-between lg:w-[30%] w-[100%] mx-auto mb-4">
                <button
                  className={`${
                    currentTab === 0
                      ? "bg-transparent border-2 border-blue-500 text-blue-500"
                      : "bg-blue-500 text-white"
                  }  px-2 lg:py-1 py-1 rounded text-xs`}
                >
                  Payment
                </button>
                <button
                  className={`${
                    currentTab === 1
                      ? "bg-transparent border-2 border-blue-500 text-blue-500"
                      : "bg-blue-500 text-white"
                  }  px-2  lg:py-1 py-1 rounded text-xs`}
                >
                  Personal Info
                </button>
                <button
                  className={`${
                    currentTab === 2
                      ? "bg-transparent border-2 border-blue-500 text-blue-500"
                      : "bg-blue-500 text-white"
                  }  px-2  lg:py-1 py-1 rounded text-xs`}
                >
                  Academic Info
                </button>
              </div>
              {paymentStatus ? (
                <VoucherForm handleNextPersonal={handleNextPersonal} />
              ) : (
                <AccountInfo />
              )}

              <hr className="mt-4" />
            </div>
          ) : null}
          {currentTab === 1 && nextPersonal ? (
            <div className="mt-4">
              <div className="mt-4">
                <div className="flex gap-4 justify-between lg:w-[30%] w-[100%] mx-auto mb-4">
                  <button
                    className={`${
                      currentTab === 0
                        ? "bg-transparent border-2 border-blue-500 text-blue-500"
                        : "bg-blue-500 text-white"
                    }  px-2 lg:py-1 py-1 rounded text-xs`}
                  >
                    Payment
                  </button>
                  <button
                    className={`${
                      currentTab === 1
                        ? "bg-transparent border-2 border-blue-500 text-blue-500"
                        : "bg-blue-500 text-white"
                    }  px-2  lg:py-1 py-1 rounded text-xs`}
                  >
                    Personal Information
                  </button>
                  <button
                    className={`${
                      currentTab === 2
                        ? "bg-transparent border-2 border-blue-500 text-blue-500"
                        : "bg-blue-500 text-white"
                    }  px-2  lg:py-1 py-1 rounded text-xs`}
                  >
                    Academic Information
                  </button>
                </div>
              </div>
              <PersonalInfoForm handleNextAcademic={handleNextAcademic} />
            </div>
          ) : null}
          {currentTab === 2 && nextAcademic ? (
            <div className="mt-4">
              <div className="mt-4">
                <div className="flex gap-4 justify-between lg:w-[30%] w-[100%] mx-auto">
                  <button
                    className={`${
                      currentTab === 0
                        ? "bg-transparent border-2 border-blue-500 text-blue-500"
                        : "bg-blue-500 text-white"
                    }  px-2 lg:py-1 py-1 rounded text-xs`}
                  >
                    Payment
                  </button>
                  <button
                    className={`${
                      currentTab === 1
                        ? "bg-transparent border-2 border-blue-500 text-blue-500"
                        : "bg-blue-500 text-white"
                    }  px-2  lg:py-1 py-1 rounded text-xs`}
                  >
                    Personal Info
                  </button>
                  <button
                    className={`${
                      currentTab === 2
                        ? "bg-transparent border-2 border-blue-500 text-blue-500"
                        : "bg-blue-500 text-white"
                    }  px-2  lg:py-1 py-1 rounded text-xs`}
                  >
                    Academic Info
                  </button>
                </div>
              </div>
              <AcademicInfoForm />
            </div>
          ) : null}
        </div>
      ) : (
        <UserData />
      )}
    </div>
  );
};

export default Dashboard;
